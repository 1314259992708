import app from '../../../vendor/matilda_core_front/scripts/app'
import { debounce } from '../../../vendor/matilda_core_front/scripts/utils'
import '../../../vendor/matilda_core_front/scripts/main'

window.addEventListener('DOMContentLoaded', () => {
    const inputs = document.querySelectorAll('.js-input-file-string')
    inputs.forEach((input) => {
        input.addEventListener('change', (e) => {
            const stringId = input.getAttribute('data-file-string')
            const string = document.getElementById(stringId)
            if (!string) return
            string.innerHTML = input.files.length ? `${input.files.length} file selezionat${input.files.length > 1 ? 'i' : 'o'}` : ''
        })
    })
})

// GESTIONE PULSANTI CHE DEVONO ESEGUIRE IL RELOAD DELLA PAGINA.
//////////////////////////////////////////////////////////////////////////////////////////////////////////

window.addEventListener('DOMContentLoaded', () => {
    const buttons = document.querySelectorAll('.js-stop-and-reload')
    buttons.forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault()
            window.location.reload()
        })
    })
})

// CODICE DI GESTIONE DEI FORM MULTIPLI PER LINGUA IN MODO DA RICORDARE L'AGGIORNA ALL'UTENTE.
//////////////////////////////////////////////////////////////////////////////////////////////////////////

window.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('.js-form-language')

    // Funzione di blocco di un form.
    function bloccaFormsTranne(excludeForm) {
        forms.forEach((form) => {
            if (excludeForm == form) return

            form.style.opacity = 0.5

            const inputs = form.querySelectorAll('input, textarea, select')
            inputs.forEach((input) => {
                input.setAttribute('disabled', 'true')
            })

            const buttons = form.querySelectorAll('button, a')
            buttons.forEach((input) => {
                input.setAttribute('disabled', 'true')
            })
        })
    }
    
    // Imposto ascoltatore di blocco dei form al change di un input.
    forms.forEach((form) => {
        const inputs = form.querySelectorAll('input, textarea')
        inputs.forEach((input) => {
            input.addEventListener('change', (e) => {
                bloccaFormsTranne(form)
            })
        })
    })
})

// CODICE DI GESTIONE DEGLI INPUT DI SELEZIONE ISTANZA.
//////////////////////////////////////////////////////////////////////////////////////////////////////////

window.addEventListener('DOMContentLoaded', () => {
    const inputs = document.querySelectorAll('.js-inputinstance')
    const modal = document.getElementById('inputinstanceModal')
    if (!modal) return
    const modalTitle = modal.querySelector('.js-inputinstance-modal-title')
    const modalSearch = modal.querySelector('.js-inputinstance-modal-search')
    const modalList = modal.querySelector('.js-inputinstance-modal-list')
    const modalController = app.getControllerForElementAndIdentifier(modal, 'modal')
    const modalUrl = modal.getAttribute('data-url')
    if (!modalSearch || !modalTitle || !modalController || !modalUrl) return

    // Inizializzo variabili utilizzate per gestire ricerca modale
    let currentInputValue = null
    let currentInputLabel = null
    let currentEntityModel = null
    let currentEntityForm = null

    // Iizializzo ascoltatore ricerca
    modalSearch.addEventListener('keyup', debounce((e) => {
        const value = modalSearch.value
        
        fetch(modalUrl + `?model=${currentEntityModel}&search=${value}`, { method: 'GET' }).then((response) => response.json()).then((response) => {
            if (!response.result) return

            modalList.innerHTML = ''
            response.payload.data.forEach((d) => {
                modalList.innerHTML += `
                <li class="c-card__body-list-item">
                    <a class="c-card__body-list-item-link js-inputinstance-modal-option" href="${d.value}">${d.label}</a>
                </li>
                `
            })

            modal.querySelectorAll('.js-inputinstance-modal-option').forEach((opt) => {
                opt.addEventListener('click', (e) => {
                    e.preventDefault()
                    if(currentInputValue) currentInputValue.value = opt.getAttribute('href')
                    if(currentInputLabel) currentInputLabel.value = opt.innerHTML

                    if (currentEntityForm) {
                        const formToSubmit = document.getElementById(currentEntityForm)
                        if (formToSubmit) {
                            formToSubmit.submit()
                        }
                    }
                    
                    modalController.close()
                })
            })
        })
    }, 250))

    // Imposto gestione per ogni inputs
    inputs.forEach((input) => {
        const buttons = input.querySelectorAll('.js-inputinstance-button')
        const value = input.querySelector('.js-inputinstance-value')
        const label = input.querySelector('.js-inputinstance-label')
        if (!value || !label) return

        // gestisco apertura selettore
        buttons.forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault()
    
                modalTitle.innerHTML = input.getAttribute('data-modal-title')
                modalList.innerHTML = ''
    
                currentEntityModel = input.getAttribute('data-entity-model')
                currentEntityForm = input.getAttribute('data-form-submit')
                currentInputLabel = label
                currentInputValue = value
    
                modalController.open()
                setTimeout(() => modalSearch.focus(), 500)
            })
        })
    })
})

// CODICE DI GESTIONE DEGLI INPUT DELLA HOMEPAGE.
//////////////////////////////////////////////////////////////////////////////////////////////////////////

window.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('.js-form-homepage')
    
    forms.forEach((form) => {
        const select = form.querySelector('.js-form-homepage-select')

        select.addEventListener('change', (e) => {
            const input = form.querySelector(`.js-form-homepage-input[data-forminput="${select.value}"]`)
            const inputs = form.querySelectorAll('.js-form-homepage-input')
            inputs.forEach((i) => { i.style.display = 'none' })
            input.style.display = 'block'
        })
    })
})

// GESTIONE LINK CON LOADING PER ATTESA DOWNLOAD FILE.
//////////////////////////////////////////////////////////////////////////////////////////////////////////

function downloadFile(urlToSend, filename, callback) {
    var req = new XMLHttpRequest();
    req.open("GET", urlToSend, true);
    req.responseType = "blob";
    req.onload = function (event) {
        console.log(req.response)
        var blob = req.response;
        var fileName = filename
        var link=document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download=fileName;
        link.click();
        callback();
    };

    req.send();
}

window.addEventListener('DOMContentLoaded', () => {
    const buttons = document.querySelectorAll('.js-file-loading')
    buttons.forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault()
            const href = button.getAttribute('href')
            const filename = button.getAttribute('data-filename') || 'file'
            const loading = $matildaCore.app.getControllerForElementAndIdentifier(document.getElementById('loadingModal'), 'modal')
            loading.open()

            downloadFile(href, filename, () => {
                loading.close()
            })
        })
    })
})